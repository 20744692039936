import { computed, useMagentoCookieState } from '#imports'

export const useSiteStoreCode = () => {
  const storeCode = computed(
    () => useMagentoCookieState().getStore() ?? 'default',
  )

  const isEcpSiteStore = computed(() => storeCode.value === 'ecp_default_us')

  const isEyeBotSiteStore = computed(
    () =>
      storeCode.value === 'eyebot_default_us' ||
      storeCode.value === 'amazon_eyebot_us',
  )

  return {
    storeCode,
    isEcpSiteStore,
    isEyeBotSiteStore,
  }
}
